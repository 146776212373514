import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../../components/seo';
import { TagModel } from '../../models';
import { PillVariant } from '../../components/pills/pill';
import HeroLayout from '../../components/layouts/hero-layout';
import { HeroElement } from '../../components/layouts';
import { TagGrid } from '../../components/tag-grid';

const useTags = (): TagModel[] => {
  const {
    allMdx: { tags },
  } = useStaticQuery(graphql`
    query {
      allMdx(limit: 2000, filter: { frontmatter: { hidden: { ne: true } } }) {
        tags: group(field: frontmatter___tags) {
          tag: fieldValue
          count: totalCount
        }
      }
    }
  `);
  return tags;
};

export const TagsPage: React.FC = () => {
  const tags = useTags();
  const hero = (
    <HeroElement
      title="Tags"
      description="A collection of subjects and themes across posts on the blog"
    />
  );
  return (
    <HeroLayout hero={hero}>
      <SEO
        title="Blog | Tags"
        description="List of tags used on the blog written by Piotr Staniów"
      />
      <TagGrid tags={tags} />
    </HeroLayout>
  );
};

export default TagsPage;
