import React from 'react';
import { TagModel } from '../models';
import { PostCardContainer } from './post-card/post-card-container';
import { PostCard } from './post-card';
import { Pill, PillVariant } from './pills/pill';

interface TagGridProps {
  tags: TagModel[];
}

export const TagGrid: React.FC<TagGridProps> = ({ tags }) => (
  <PostCardContainer>
    {tags.map(({ tag, count }) => (
      <PostCard
        key={tag}
        path={`/blog/tags/${tag}`}
        title={<Pill variant={PillVariant.BOLD}>{`#${tag}`}</Pill>}
        description={`${count} post${count > 1 ? 's' : ''} in this series`}
      />
    ))}
  </PostCardContainer>
);
